import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // ✅ Для декодирования токена

function MainPage() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [googleCalendarId, setGoogleCalendarId] = useState(null);

  useEffect(() => {
    // 🔹 Получаем токен из localStorage
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        console.log("📌 [DEBUG] Декодированный токен:", decoded);
        
        if (decoded.google_calendar_id) {
          setGoogleCalendarId(decoded.google_calendar_id);
          console.log("📌 [DEBUG] Установлен googleCalendarId:", decoded.google_calendar_id);
        } else {
          console.warn("⚠️ В токене нет google_calendar_id");
        }

         // ✅ Проверяем, является ли пользователь администратором
      if (decoded.role && decoded.role.includes("Admin")) {
        setIsAdmin(true);
      }


      } catch (error) {
        console.error("❌ Ошибка декодирования токена:", error);
      }
    } else {
      console.warn("⚠️ Токен отсутствует в localStorage!");
    }
  }, []);

  // ✅ Функция для открытия Google Calendar в новой вкладке
  const openGoogleCalendar = () => {
    if (googleCalendarId) {
      const calendarUrl = `https://calendar.google.com/calendar/u/0/r?cid=${googleCalendarId}`;
      window.open(calendarUrl, "_blank"); // Открываем календарь в новой вкладке
    } else {
      alert("Календарь недоступен!"); // Если ID не установлен
    }
  };

  return (
    <div style={{ backgroundColor: "#ffffff", minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      
      {/* Центр страницы с кнопками */}
      <main
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Link
          to="/create-order"
          className="btn btn-lg"
          style={{
            width: "250px",
            border: "1px solid #90caf9",
            color: "#000",
            backgroundColor: "#fff",
            transition: "0.3s",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#e3f2fd")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
        >
          Создать заказ
        </Link>
        <Link
          to="/orders"
          className="btn btn-lg"
          style={{
            width: "250px",
            border: "1px solid #90caf9",
            color: "#000",
            backgroundColor: "#fff",
            transition: "0.3s",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#e3f2fd")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
        >
          Список заказов
        </Link>
        <Link
          to="/prepare-order"
          className="btn btn-lg"
          style={{
            width: "250px",
            border: "1px solid #90caf9",
            color: "#000",
            backgroundColor: "#fff",
            transition: "0.3s",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#e3f2fd")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
        >
          Подготовка заказа
        </Link>
        <Link
          to="/upload-results"
          className="btn btn-lg"
          style={{
            width: "250px",
            border: "1px solid #90caf9",
            color: "#000",
            backgroundColor: "#fff",
            transition: "0.3s",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#e3f2fd")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
        >
          Загрузка результатов
        </Link>
        <Link
          to="/archived-orders"
          className="btn btn-lg"
          style={{
            width: "250px",
            border: "1px solid #90caf9",
            color: "#000",
            backgroundColor: "#fff",
            transition: "0.3s",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#e3f2fd")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
        >
          Архив заказов
        </Link>

        {/* ✅ КНОПКА "МОЙ КАЛЕНДАРЬ" */}
        <button
          onClick={openGoogleCalendar}
          className="btn btn-lg"
          style={{
            marginTop: "30px", // 🔹 Отступ от кнопки "Архив заказов"
            width: "250px",
            border: "1px solid #90caf9",
            color: "#000",
            backgroundColor: "#fff",
            transition: "0.3s",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#e3f2fd")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
        >
          Мой календарь
        </button>


      {/* 🔹 Админ панель (видна только администраторам) */}
{isAdmin && (
 <div style={{
  marginTop: "-350px",
  marginLeft: "1400px",
  textAlign: "center"
}}>
  <h4 style={{ color: "#6c757d" }}>Админ панель:</h4>

  {/* Контейнер для кнопок */}
  <div style={{
    display: "flex",
    flexDirection: "column", // Ставим кнопки вертикально
    alignItems: "center",    // Центрируем по горизонтали
    gap: "15px"              // Отступ между кнопками
  }}>
    <Link
      to="/template-management"
      className="btn btn-lg"
      style={{
        width: "250px",
        border: "1px solid #90caf9",
        color: "#000",
        backgroundColor: "#fff",
        transition: "0.3s",
      }}
      onMouseEnter={(e) => (e.target.style.backgroundColor = "#e3f2fd")}
      onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
    >
      Протоколы
    </Link>
{/* 
    <Link
      to="/assign-technician"
      className="btn btn-lg"
      style={{
        width: "250px",
        border: "1px solid #90caf9",
        color: "#000",
        backgroundColor: "#fff",
        transition: "0.3s",
      }}
      onMouseEnter={(e) => (e.target.style.backgroundColor = "#e3f2fd")}
      onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
    >
      Назначить техников
    </Link>

*/}
  </div>
</div>

)}



      </main>

      {/* Футер */}
      <footer
        style={{
          padding: "10px 20px",
          backgroundColor: "#f8f9fa",
          textAlign: "center",
          fontSize: "0.9rem",
          color: "#6c757d",
        }}
      >
        © 2025 AZF-PROCESS
      </footer>
    </div>
  );
}

export default MainPage;
