import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function UploadResults() {
  const [orders, setOrders] = useState([]); // Список заказов
  const [selectedOrder, setSelectedOrder] = useState(''); // Выбранный заказ
  const [file, setFile] = useState(null); // Загружаемый файл
  const [results, setResults] = useState([]); // Список загруженных результатов
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchOrders();
    fetchResults();
  }, []);

  // Функция загрузки списка заказов
  const fetchOrders = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/orders`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const data = await response.json();
      if (response.ok) {
        setOrders(data);
      } else {
        setError(data.message || 'Ошибка при загрузке заказов.');
      }
    } catch (err) {
      setError(err.message || 'Ошибка при загрузке заказов.');
    } finally {
      setLoading(false);
    }
  };

// Функция загрузки списка результатов (только для выбранного заказа)
const fetchResults = async (orderId) => {
  if (!orderId) return;

  try {
    const response = await fetch(`/api/results/${orderId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    if (!response.ok) {
      throw new Error('Ошибка загрузки данных');
    }

    const data = await response.json();

    // Проверяем, является ли data массивом (если нет — устанавливаем пустой)
    setResults(Array.isArray(data) ? data : []);
  } catch (err) {
    console.error('Ошибка при загрузке результатов:', err);
    setError('Ошибка при загрузке результатов.');
  }
};


const handleDeleteFile = async (orderId, fileName) => {
  const confirmDelete = await MySwal.fire({
    title: 'Вы уверены?',
    text: 'Файл будет удален без возможности восстановления!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
    confirmButtonText: 'Удалить',
    cancelButtonText: 'Отмена',
  });

  if (!confirmDelete.isConfirmed) return;

  try {
    const response = await fetch(`/api/results/${orderId}/${fileName}`, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    if (response.ok) {
      MySwal.fire({
        icon: 'success',
        title: 'Файл удален!',
        text: 'Результат успешно удален.',
        timer: 2000,
        showConfirmButton: false,
      });
      fetchResults(orderId); // Обновляем список после удаления
    } else {
      MySwal.fire({
        icon: 'error',
        title: 'Ошибка удаления',
        text: 'Не удалось удалить файл.',
      });
    }
  } catch (err) {
    console.error('Ошибка при удалении файла:', err);
    MySwal.fire({
      icon: 'error',
      title: 'Ошибка сервера',
      text: 'Произошла ошибка. Попробуйте снова.',
    });
  }
};



  // Обработчик загрузки файла
  const handleFileUpload = async (e) => {
    e.preventDefault();

    if (!selectedOrder || !file) {
      MySwal.fire({
        icon: 'warning',
        title: 'Ошибка',
        text: 'Выберите заказ и файл для загрузки!',
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('order_id', selectedOrder);

    console.log('Отправляем formData:', selectedOrder, file);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/upload-results`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        MySwal.fire({
          icon: 'success',
          title: 'Файл загружен!',
          text: 'Результаты успешно добавлены.',
          timer: 2000,
          showConfirmButton: false,
        });
        fetchResults(selectedOrder);
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Ошибка загрузки',
          text: data.message || 'Не удалось загрузить файл.',
        });
      }
    } catch (err) {
      console.error('Ошибка при загрузке файла:', err);
      MySwal.fire({
        icon: 'error',
        title: 'Ошибка сервера',
        text: 'Произошла ошибка. Попробуйте снова.',
      });
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Загрузка результатов измерений</h2>
      <div className="card mx-auto shadow-sm" style={{ maxWidth: '600px' }}>
        <div className="card-body">
          {loading && <p>Загрузка...</p>}
          {error && <p className="text-danger">{error}</p>}
          {!loading && !error && (
            <>
              <form onSubmit={handleFileUpload}>
                <div className="mb-3">
                  <label htmlFor="order" className="form-label">Выберите заказ</label>
                  <select
                        id="order"
                        className="form-control"
                        value={selectedOrder}
                        onChange={(e) => {
                          setSelectedOrder(e.target.value);
                          fetchResults(e.target.value); // Загружаем файлы только для этого заказа
                        }}
                        required
                        >
                    <option value="">Выберите заказ</option>
                    {orders.map((order) => (
                      <option key={order.id} value={order.id}>
                    {order.id} - {order.orderType} - {order.contactPerson} - {order.customer} - {order.address}
                  </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="file" className="form-label">Выберите файл</label>
                  <input
                    type="file"
                    id="file"
                    className="form-control"
                    onChange={(e) => setFile(e.target.files[0])}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-outline-primary w-100">
                  Загрузить
                </button>
              </form>
              <hr />
              <h5>Загруженные результаты</h5>
              <table className="table table-bordered text-center">
  <thead className="table-light">
    <tr>
      <th>Заказ</th>
      <th>Файл</th>
      <th>Дата загрузки</th>
      <th>Действие</th> {/* Добавленный заголовок для кнопки удаления */}
    </tr>
  </thead>
  <tbody>
  {results.length > 0 ? (
    results.map((result) => (
      <tr key={result.id}>
        <td>{result.order_id}</td>
        <td>{result.original_name}</td> {/* Имя файла теперь просто текст */}
        <td>{result.upload_date.split('T')[0]}</td>
        <td>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handleDeleteFile(result.order_id, result.file_name)}
          >
            Удалить
          </button>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="4" className="text-center text-muted">
        Нет загруженных результатов
      </td>
    </tr>
  )}
</tbody>
</table>

            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default UploadResults;
