import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');



  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = process.env.REACT_APP_API_URL || "/api"; // Защита от undefined
const token = localStorage.getItem("token") || ""; // Убедимся, что не передаём null

const response = await fetch(`${apiUrl}/login`, {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`
  },
  body: JSON.stringify({ email, password })
});

      

      const data = await response.json();
      if (response.ok) {
        MySwal.fire({
          icon: 'success',
          title: 'Вход выполнен!',
          text: 'Добро пожаловать!',
          timer: 2000,
          showConfirmButton: false,
        });

        localStorage.setItem('token', data.token); // Сохраняем токен
        localStorage.setItem('role', data.role); // Сохраняем роль
        if (data.name) {
          localStorage.setItem('name', data.name);
          console.log("Сохраненное имя пользователя:", data.name); // 🔍 Проверка
        } else {
          console.warn("⚠️ Сервер НЕ отправил name!");
        }
        


// Через 2 секунды перенаправление на главную и обновление страницы
setTimeout(() => {
  navigate('/'); // Перебрасываем на главную страницу
  window.location.reload(); // Обновляем страницу, чтобы имя пользователя появилось в навигации
}, 2000);
} else {
MySwal.fire({
  icon: "error",
  title: "Ошибка входа",
  text: data.message || "Неверные учетные данные.",
});
}
} catch (error) {
console.error("Ошибка при входе:", error);
MySwal.fire({
icon: "error",
title: "Ошибка сервера",
text: "Попробуйте снова позже.",
});
}
};

  

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card shadow-lg" style={{ maxWidth: '400px', width: '100%' }}>
        <div className="card-header text-center bg-primary text-white">
          <h4 className="mb-0">Вход в систему</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleLogin}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Пароль</label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-outline-primary w-100">Войти</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
