import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

function PrepareOrder() {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [creationDate, setCreationDate] = useState(null);
  const [deadline, setDeadline] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);


  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/orders`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          const filteredOrders = data.filter(order => order.status !== "Завершен" && order.status !== "Утвержден");
          setOrders(filteredOrders);
        } else {
          alert(data.message || 'Ошибка при загрузке заказов.');
        }
      } catch (error) {
        console.error('Ошибка при загрузке заказов:', error);
      }
    };
    
    fetchOrders();
  }, []);

  const handleOrderChange = (e) => {
    const orderId = e.target.value;
    setSelectedOrder(orderId);

    if (orderId) {
      const order = orders.find((o) => o.id === parseInt(orderId));
      if (order) {
        setCreationDate(order.orderDate);
      }
    }
  };

  const handleSetDeadline = async () => {
    if (!selectedOrder) {
      Swal.fire({
        icon: 'warning',
        title: 'Ошибка',
        text: 'Сначала выберите заказ!',
      });
      return;
    }

    if (!creationDate) {
      Swal.fire({
        icon: 'error',
        title: 'Ошибка',
        text: 'Не удалось определить дату создания заказа.',
      });
      return;
    }

    const minDate = new Date(creationDate);
    minDate.setDate(minDate.getDate() + 1);

    const { value: selectedDate } = await Swal.fire({
      title: 'Выберите крайний срок выполнения',
      html: `<input type="date" id="deadline" class="swal2-input" min="${minDate.toISOString().split('T')[0]}">`,
      preConfirm: () => {
        const deadlineInput = document.getElementById('deadline').value;
        if (!deadlineInput) {
          Swal.showValidationMessage('Выберите дату!');
        }
        return deadlineInput;
      },
    });

    if (selectedDate) {
      setDeadline(selectedDate);
      Swal.fire({
        icon: 'success',
        title: 'Дата установлена',
        text: `Крайний срок: ${selectedDate}`,
      });
    }
  };

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedOrder || !deadline) {
      Swal.fire({
        icon: 'error',
        title: 'Ошибка',
        text: 'Выберите заказ и установите крайний срок!',
      });
      return;
    }

    const formData = new FormData();
    formData.append('orderId', selectedOrder);
    formData.append('deadline', deadline);



    for (const file of selectedFiles) {
      formData.append('files', file);
    }

    try {
      const response = await fetch(`/api/prepare-order/${selectedOrder}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
    });
    

      const data = await response.json();
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Успех',
          text: selectedFiles.length > 0
            ? 'Файлы успешно загружены и прикреплены к заказу!'
            : 'Подготовка заказа завершена без прикрепления файлов.',
          }).then(() => {
            window.location.href = '/orders'; 
        });

        setSelectedOrder(null);
        setDeadline('');
        setSelectedFiles([]);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: data.message || 'Ошибка при загрузке данных.',
        });
      }
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
      Swal.fire({
        icon: 'error',
        title: 'Ошибка',
        text: 'Произошла ошибка. Пожалуйста, попробуйте снова.',
      });
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Подготовка заказа к работе</h2>
      <div className="card mx-auto" style={{ maxWidth: '800px' }}>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="orderSelect" className="form-label">Выберите заказ</label>
              <select
                id="orderSelect"
                className="form-control"
                value={selectedOrder || ''}
                onChange={handleOrderChange}
                required
              >
                <option value="">Выберите заказ</option>
                {orders.map((order) => (
                  <option key={order.id} value={order.id}>
                  {order.id} - {order.orderType} - {order.contactPerson} - {order.customer} - {order.address}
                </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label">Крайний срок выполнения</label>
              <button
                type="button"
                className="btn w-100"
                style={{
                  border: '1px solid #007bff',
                  backgroundColor: 'transparent',
                  color: '#007bff',
                  transition: 'all 0.3s',
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = '#007bff';
                  e.target.style.color = '#fff';
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = 'transparent';
                  e.target.style.color = '#007bff';
                }}
                onClick={handleSetDeadline}
              >
                Выбрать дату
              </button>
              {deadline && <p className="mt-2 text-success">Крайний срок: {deadline}</p>}
            </div>

            <div className="mb-3">
              <label htmlFor="fileUpload" className="form-label">
                Загрузить файлы (Excel, JPEG, PNG, PDF, DWG) <span className="text-muted">*Опционально</span>
              </label>
              <input
                type="file"
                id="fileUpload"
                className="form-control"
                multiple
                accept=".xlsx,.xls,.csv,.jpeg,.jpg,.png,.pdf,.dwg"
                onChange={handleFileChange}
              />
            </div>

            <button
              type="submit"
              className="btn w-100"
              style={{
                border: '1px solid #007bff',
                backgroundColor: 'transparent',
                color: '#007bff',
                transition: 'all 0.3s',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#007bff';
                e.target.style.color = '#fff';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'transparent';
                e.target.style.color = '#007bff';
              }}
            >
              {selectedFiles.length > 0 ? 'Загрузить файлы' : 'Готово'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PrepareOrder;
