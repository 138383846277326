  import React, { useState } from 'react';
  import 'flatpickr/dist/themes/material_blue.css'; // Используем стиль flatpickr
  import Swal from 'sweetalert2'; // Импортируем SweetAlert2

  function CreateOrder() {
    const [orderType, setOrderType] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [customer, setCustomer] = useState('');




    const handleSubmit = async (e) => {
      e.preventDefault();
    
      if (!orderType || !contactPerson || !phoneNumber || !email || !address || !customer) {
        Swal.fire({
          icon: "warning",
          title: "Ошибка",
          text: "Все поля обязательны для заполнения!",
        });
        return;
      }
    
      try {
        const token = localStorage.getItem("token");
    
        // Загружаем полевых и офисных техников по orderType
        const [fieldRes, officeRes] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/api/technicians/by-method?orderType=${orderType}&type=field`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          fetch(`${process.env.REACT_APP_API_URL}/api/technicians/by-method?orderType=${orderType}&type=office`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);
    
        const fieldTechs = await fieldRes.json();
        const officeTechs = await officeRes.json();
    
        const defaultFieldId = fieldTechs[0]?.id;
        const defaultOfficeId = officeTechs[0]?.id;
    
        const fieldOptions = fieldTechs.map(t => `<option value="${t.id}">${t.name}</option>`).join('');
        const officeOptions = officeTechs.map(t => `<option value="${t.id}">${t.name}</option>`).join('');
    
        const { value: selectedTechs } = await Swal.fire({
          title: 'Подтвердите техников',
          html: `
            <label>Полевой техник</label>
            <select id="fieldTech" class="swal2-input">${fieldOptions}</select>
            <label>Офисный техник</label>
            <select id="officeTech" class="swal2-input">${officeOptions}</select>
          `,
          didOpen: () => {
            document.getElementById('fieldTech').value = defaultFieldId;
            document.getElementById('officeTech').value = defaultOfficeId;
          },
          preConfirm: () => {
            return {
              technician_id: document.getElementById('fieldTech').value,
              office_technician_id: document.getElementById('officeTech').value,
            };
          },
          confirmButtonText: 'Подтвердить',
          showCancelButton: true,
          cancelButtonText: 'Отмена',
        });
    
        if (!selectedTechs) return;
    
        // Отправляем заказ с выбранными техниками
        const response = await fetch(`${process.env.REACT_APP_API_URL}/orders`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            orderType,
            contactPerson,
            phoneNumber,
            email,
            address,
            customer,
            technician_id: selectedTechs.technician_id,
            office_technician_id: selectedTechs.office_technician_id,
          }),
        });
    
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Ошибка при создании заказа.");
        }
    
        await Swal.fire({
          icon: "success",
          title: "Успех",
          text: "Заказ успешно создан!",
        });
    
        window.location.href = "/prepare-order";
    
        // Сброс формы
        setOrderType("");
        setContactPerson("");
        setPhoneNumber("");
        setEmail("");
        setAddress("");
        setCustomer("");
      } catch (error) {
        console.error("Ошибка при создании заказа:", error);
        Swal.fire({
          icon: "error",
          title: "Ошибка",
          text: error.message || "Произошла ошибка. Попробуйте снова.",
        });
      }
    };
    
    

    return (
      <div className="container mt-5">
        <h2 className="text-center mb-4">Создать заказ</h2>
        <div className="card mx-auto" style={{ maxWidth: '600px' }}>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="orderType" className="form-label">Тип измерения</label>
                <select
                  id="orderType"
                  className="form-control"
                  value={orderType}
                  onChange={(e) => setOrderType(e.target.value)}
                  required
                >
                  <option value="">Выберите тип измерения</option>
                  <option value="Вентиляция">Вентиляция</option>
                  <option value="Шум от техносистем">Шум от техносистем</option>
                  <option value="Опрессовка вентиляционных систем">Опрессовка вентиляционных систем</option>
                  <option value="Освещенность">Освещенность</option>
                  <option value="Замеры уровня звукоизоляции">Замеры уровня звукоизоляции</option>
                  <option value="Термосъёмка">Термосъёмка</option>
                  <option value="Вибрация">Вибрация</option>
                  <option value="Микроклимат">Микроклимат</option>
                </select>
              </div>
              
              <div className="mb-3">
                <label htmlFor="contactPerson" className="form-label">Контактное лицо</label>
                <input
                  type="text"
                  id="contactPerson"
                  className="form-control"
                  value={contactPerson}
                  onChange={(e) => setContactPerson(e.target.value)}
                  required
                />
              </div>
                        <div className="mb-3">
              <label htmlFor="customer" className="form-label">Заказчик</label>
              <input
                type="text"
                id="customer"
                className="form-control"
                value={customer}
                onChange={(e) => setCustomer(e.target.value)}
                required
              />
            </div>

                        <div className="mb-3">
                <label htmlFor="address" className="form-label">Адрес объекта</label>
                <input
                  type="text"
                  id="address"
                  className="form-control"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phoneNumber" className="form-label">Номер телефона</label>
                <input
                  type="text"
                  id="phoneNumber"
                  className="form-control"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Электронная почта</label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
          
            
  </div>

              <button type="submit" className="btn btn-primary w-100">Создать заказ</button>
            </form>
          </div>
        </div>
      </div>
    );
  }

  export default CreateOrder;
