import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";




// Функция для форматирования даты
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const offsetDate = new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
  return offsetDate.toISOString().split("T")[0];
};

function Orders() {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState("");
  const [filters, setFilters] = useState({
    id: "",
    orderType: "",
    deadline: "",
    contactPerson: "",
    status: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchOrders = async () => {
      Swal.fire({
        title: "Загрузка...",
        text: "Пожалуйста, подождите",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/orders`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data = await response.json();

        if (Array.isArray(data)) {
          setOrders(data);
        } else {
          console.error("Неверный формат данных:", data);
          setOrders([]);
        }
      } catch (err) {
        setError("Ошибка загрузки данных. Проверьте сервер.");
        console.error("Ошибка при загрузке заказов:", err);
      } finally {
        Swal.close();
      }
    };

    fetchOrders();
  }, []);




  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const filteredOrders = orders.filter((order) => {
    return (
        (!filters.id || order.id.toString().includes(filters.id)) &&
        (!filters.orderType || order.orderType.toLowerCase().includes(filters.orderType.toLowerCase())) &&
        (!filters.deadline || formatDate(order.deadline).includes(filters.deadline)) &&
        (!filters.contactPerson || order.contactPerson.toLowerCase().includes(filters.contactPerson.toLowerCase())) &&
        (!filters.status || getStatusText(order.status).toLowerCase().includes(filters.status.toLowerCase()))

    );
});

const getStatusText = (status) => {
  switch (status) {
    case "Ожидает результатов измерений": return "Подготовка";
    case "Выполняется": return "Оформление";
    case "Завершён": return "Завершен";
    case "Утверждён": return "Архив";
    default: return status || "Новый";
  }
};


  const paginatedOrders = filteredOrders.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  const handlePageChange = (page) => {
    Swal.fire({
      title: "Загрузка...",
      text: "Пожалуйста, подождите",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setTimeout(() => {
      setCurrentPage(page);
      Swal.close();
    }, 500);
  };

  if (error) return <p className="text-danger">Ошибка: {error}</p>;

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Список заказов</h2>


      <div className="mb-3">
        <div className="row">
        {[
  { key: "id", label: "ID заказа" },
  { key: "orderType", label: "Тип измерения" },
  { key: "deadline", label: "Крайний срок" },
  { key: "contactPerson", label: "Контактное лицо" },
  { key: "status", label: "Статус заказа" },
].map(({ key, label }) => (
  <div className="col" key={key}>
    <input
      type="text"
      name={key}
      className="form-control"
      placeholder={`Фильтр по ${label}`}
      value={filters[key] || ""}
      onChange={handleFilterChange}
    />
  </div>
))}

        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered" style={{ borderColor: "#d3d3d3" }}>
          <thead className="table-light">
            <tr>
              <th>ID</th>
              <th>Тип измерения</th>
              <th>Крайний срок выполнения</th>
              <th>Контактное лицо</th>
              <th>Статус</th>
              <th>Управление</th>
            </tr>
          </thead>
          <tbody>
            {paginatedOrders.map((order) => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>{order.orderType}</td>
                <td>{order.deadline ? formatDate(order.deadline) : "Не указан"}</td>
                <td>{order.contactPerson}</td>
                <td>{getStatusText(order.status)}</td>


                <td>
              <button
                className="btn btn-outline-primary btn-sm"
                style={{ transition: "0.3s", border: "1px solid #008bff" }}
                onMouseEnter={(e) => e.target.style.backgroundColor = "#80bfff"}
                onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"}
                onClick={() => window.location.href = `/order-management/${order.id}`}
              >
                Управление заказом
              </button>
            </td>


              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-center mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={`btn ${currentPage === index + 1 ? "btn-primary" : "btn-outline-primary"} mx-1`}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Orders;
