import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { jwtDecode } from 'jwt-decode';



function OrderDetails() {
  const { id } = useParams();
  const [data, setData] = useState({ documents: [], results: [], protocols: [] });
  const [error, setError] = useState('');
  const [isApproved, setIsApproved] = useState(false);
  
  const [isAdmin, setIsAdmin] = useState(false);





  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
        try {
            const decoded = jwtDecode(token);
            console.log("📌 [DEBUG] Декодированный токен:", decoded);

            // Проверяем, есть ли "Admin" в массиве ролей
            const roles = JSON.parse(decoded.role);
            if (roles.includes("Admin")) {
                setIsAdmin(true); // Если роль Admin, устанавливаем флаг isAdmin
            }
        } catch (error) {
            console.error("❌ Ошибка декодирования токена:", error);
        }
    }
}, []);


  // Загрузка данных заказа
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(`/api/orders/${id}/details`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
  
        if (!response.ok) {
          throw new Error('Не удалось загрузить данные заказа');
        }
  
        const orderData = await response.json();
        console.log('Полученные данные заказа:', orderData); // Логируем ответ сервера
  
        // Обновляем документы и результаты
        setData({
          documents: orderData.documents || [],
          results: orderData.results || [],
          protocols: orderData.protocols || [], // 🔹 Добавляем протоколы
        });
        
  
        // Проверяем, есть ли отметка утверждения (по `approved_at`)
        setIsApproved(!!orderData.approved_at); 
  
        // Очищаем ошибку
        setError('');
      } catch (err) {
        console.error('Ошибка при загрузке заказа:', err);
        setError(''); // Очищаем ошибку
      }
    };
  
    fetchOrderDetails();
  }, [id]);
  
  

const downloadFile = async (folder, fileName) => {
  try {
      const token = localStorage.getItem('token'); // Получаем токен

      const response = await fetch(`/api/download/${folder}/${id}/${fileName}`, {
          method: 'GET',
          headers: {
              Authorization: `Bearer ${token}`
          }
      });

      if (!response.ok) {
          throw new Error('Ошибка при скачивании файла');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

  } catch (error) {
      console.error('Ошибка при скачивании файла:', error);
  }
};




  // Утверждение отчета с проверками
  const approveOrder = async () => {
    // Проверяем наличие документации и результатов
    const noDocs = data.documents.length === 0;
    const noResults = data.results.length === 0;

    let confirmationMessage = 'Вы уверены, что хотите утвердить отчет?';
    if (noDocs && noResults) {
      confirmationMessage = 'Вы уверены, что хотите утвердить отчет без документации и результатов измерений?';
    } else if (noDocs) {
      confirmationMessage = 'Вы уверены, что хотите утвердить отчет без документации?';
    } else if (noResults) {
      confirmationMessage = 'Вы уверены, что хотите утвердить отчет без результатов измерений?';
    }

    const result = await Swal.fire({
      title: 'Подтверждение действия',
      text: confirmationMessage,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да, утвердить',
      cancelButtonText: 'Отмена',
    });

    if (!result.isConfirmed) return;

    // Утверждение отчета
    try {
      const response = await fetch(`/api/orders/${id}/approve`, {
        method: 'PATCH',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      if (response.ok) {
        Swal.fire('Успех!', 'Отчёт успешно утверждён.', 'success');
        setIsApproved(true); 
      } else {
        throw new Error('Не удалось утвердить отчет');
      }
    } catch (err) {
      Swal.fire('Ошибка!', err.message, 'error');
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Детали заказа #{id}</h2>
      {error && <p className="text-danger">Ошибка: {error}</p>}

      <div className="card shadow mb-4">
        <div className="card-header bg-light">
          <h4 className="mb-0">Документация</h4>
        </div>
        <div className="card-body">
          {data.documents.length > 0 ? (
            <ul className="list-group">
              {data.documents.map((doc) => (
                <li key={doc.id} className="list-group-item d-flex justify-content-between align-items-center">
                  <span>{doc.original_name}</span>
                        <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => downloadFile('documents', doc.file_name)}
                  >
                    Скачать
                  </button>

                </li>
              ))}
            </ul>
          ) : (
            <p className="text-muted">Документы отсутствуют</p>
          )}
        </div>
      </div>

      <div className="card shadow mb-4">
        <div className="card-header bg-light">
          <h4 className="mb-0">Результаты измерений</h4>
        </div>
        <div className="card-body">
          {data.results.length > 0 ? (
            <ul className="list-group">
              {data.results.map((res) => (
                <li key={res.id} className="list-group-item d-flex justify-content-between align-items-center">
                  <span>{res.original_name}</span>
                  <button
  className="btn btn-outline-primary btn-sm"
  onClick={() => downloadFile('results', res.file_name)}
>
  Скачать
</button>

                </li>
              ))}
            </ul>
          ) : (
            <p className="text-muted">Результаты отсутствуют</p>
          )}
        </div>
      </div>

      {/* 📜 Протоколы */}
<div className="card shadow mb-4">
  <div className="card-header bg-light">
    <h4 className="mb-0">Протоколы</h4>
  </div>
  <div className="card-body">
    {data.protocols.length > 0 ? (
      <ul className="list-group">
        {data.protocols.map((protocol) => (
          <li key={protocol.id} className="list-group-item d-flex justify-content-between align-items-center">
            <span>{protocol.original_name || protocol.file_name}</span>
            <button className="btn btn-outline-primary btn-sm" onClick={() => downloadFile('protocols', protocol.file_name)}>
              Скачать
            </button>
          </li>
        ))}
      </ul>
    ) : (
      <p className="text-muted">Протоколы отсутствуют</p>
    )}
  </div>
</div>


      <div className="text-center mt-4">
      {!isApproved ? (
  isAdmin && ( // Показываем кнопку только если isAdmin === true
    <button
      className="btn"
      style={{
        border: '1px solid #28a745',
        backgroundColor: 'transparent',
        color: '#28a745',
        transition: 'all 0.3s',
      }}
      onMouseEnter={(e) => {
        e.target.style.backgroundColor = '#28a745';
        e.target.style.color = '#fff';
      }}
      onMouseLeave={(e) => {
        e.target.style.backgroundColor = 'transparent';
        e.target.style.color = '#28a745';
      }}
      onClick={approveOrder}
    >
      Утвердить отчет
    </button>
  )
) : (
  <div>
    <span className="text-success">Отчет утвержден!</span>
    <br />
    <a 
        href="/archived-orders"
        className="text-primary"
        style={{ cursor: 'pointer', textDecoration: 'underline' }}
    >
        Перейти в архив заказов
    </a>
  </div>
)}
</div>
    </div>
  );
}

export default OrderDetails;
