  import React, { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';

function ArchivedOrders() {
  const [orders, setOrders] = useState([]);
  const [search,] = useState('');
  const [filters, setFilters] = useState({
    orderId: '',
    clientName: '',
    dateFrom: '',
    dateTo: '',
  });

  const [error, setError] = useState('');

  // Функция загрузки архивированных заказов
  const fetchArchivedOrders = useCallback(async () => {
    try {
      const params = new URLSearchParams({ ...filters, search }).toString();
      const response = await fetch(`/api/archived-orders?${params}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      if (!response.ok) {
        throw new Error('Не удалось загрузить архивированные заказы');
      }

      const data = await response.json();
      setOrders(data);
    } catch (err) {
      setError(err.message);
    }
  }, [filters, search]);

  // Загружаем заказы при изменении фильтров или строки поиска
  useEffect(() => {
    fetchArchivedOrders();
  }, [fetchArchivedOrders]);

  // Обработка изменения фильтров
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  // Функция для скачивания архива
  const downloadArchive = async (orderId) => {
    try {
      const response = await fetch(`/api/archive/${orderId}/download`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      if (!response.ok) {
        throw new Error('Не удалось скачать архив.');
      }

      // Создание ссылки для скачивания
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'archive.zip'); // Имя файла по умолчанию
      document.body.appendChild(link);
      link.click();
      link.remove();

      Swal.fire({
        title: 'Успех!',
        text: 'Архив успешно скачан.',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false,
      });
    } catch (err) {
      setError(err.message);
      Swal.fire({
        title: 'Ошибка!',
        text: err.message,
        icon: 'error',
      });
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Архивированные заказы</h2>
      {error && <p className="text-danger">Ошибка: {error}</p>}

      <div className="mb-4 p-3 border rounded bg-light">
        <h5 className="mb-3">Фильтры</h5>
        <div className="row g-3">
          <div className="col-md-3">
            <input
              type="text"
              name="orderId"
              placeholder="Номер заказа"
              className="form-control"
              value={filters.orderId}
              onChange={handleFilterChange}
            />
          </div>
          <div className="col-md-3">
            <input
              type="text"
              name="clientName"
              placeholder="Имя клиента"
              className="form-control"
              value={filters.clientName}
              onChange={handleFilterChange}
            />
          </div>
          <div className="col-md-3">
  <label className="text-muted small">От:</label>
  <input
    type="date"
    name="dateFrom"
    className="form-control"
    value={filters.dateFrom}
    onChange={handleFilterChange}
  />
</div>
<div className="col-md-3">
  <label className="text-muted small">До:</label>
  <input
    type="date"
    name="dateTo"
    className="form-control"
    value={filters.dateTo}
    onChange={handleFilterChange}
  />
</div>

        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered" style={{ borderColor: '#d3d3d3' }}>
          <thead className="table-light">
            <tr>
              <th>#</th>
              <th>Тип заказа</th>
              <th>Дата</th>
              <th>Клиент</th>
              <th>Статус</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            {orders.length > 0 ? (
              orders.map((order) => (
                <tr key={order.id}>
                  <td>{order.id}</td>
                  <td>{order.orderType}</td>
                  <td>{new Date(order.orderDate).toLocaleDateString()}</td>
                  <td>{order.contactPerson}</td>
                  <td>{order.status}</td>
                  <td>
                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => downloadArchive(order.id)}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#007bff';
                        e.target.style.color = '#fff';
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = 'transparent';
                        e.target.style.color = '#007bff';
                      }}
                    >
                      Скачать
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-muted">
                  Заказы не найдены
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ArchivedOrders;
