import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function OrderManagement() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  

  // ✅ Загружаем информацию о заказе
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await fetch(`/api/orders/${orderId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) throw new Error("Ошибка загрузки заказа");
        const data = await response.json();
        setOrder(data);
      } catch (error) {
        setError("Ошибка загрузки заказа. Проверьте сервер.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [orderId]);


  // ✅ Завершение заказа
  const handleComplete = async () => {
    if (!order) return;

    const result = await MySwal.fire({
      title: "Вы уверены?",
      text: "Это действие завершит заказ!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Завершить",
      cancelButtonText: "Отмена",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `/api/orders/${order.id}/complete`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.ok) {
          MySwal.fire("Успех!", "Заказ успешно завершен!", "success");
          setOrder((prevOrder) => ({ ...prevOrder, status: "Завершен" }));
        } else {
          MySwal.fire("Ошибка!", "Не удалось завершить заказ.", "error");
        }
      } catch (error) {
        console.error("Ошибка при завершении заказа:", error);
      }
    }
  };

  // ✅ Удаление заказа
  const handleDelete = async () => {
    if (!order) return;

    const result = await MySwal.fire({
      title: "Вы уверены?",
      text: "Это действие удалит заказ!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Удалить",
      cancelButtonText: "Отмена",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(`/api/orders/${order.id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.ok) {
          MySwal.fire("Удалено!", "Заказ успешно удален.", "success");
          navigate("/orders");
        } else {
          MySwal.fire("Ошибка!", "Не удалось удалить заказ.", "error");
        }
      } catch (error) {
        console.error("Ошибка при удалении заказа:", error);
      }
    }
  };


// 📤 **Функция загрузки протокола**
const handleUploadProtocol = async (event) => {
  if (!order) return;

  const file = event.target.files[0];
  if (!file) return;

  const formData = new FormData();
  formData.append("protocol", file);

  try {
    const response = await fetch(`/api/api/orders/${orderId}/upload-protocol`, {
      method: "POST",
      headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      body: formData
  });
  

    if (response.ok) {
      MySwal.fire("Успех!", "Протокол успешно загружен!", "success");
    } else {
      MySwal.fire("Ошибка!", "Не удалось загрузить протокол.", "error");
    }
  } catch (error) {
    console.error("❌ Ошибка при загрузке протокола:", error);
    MySwal.fire("Ошибка!", "Ошибка загрузки файла.", "error");
  }
};


// ✅ Функция скачивания шаблона

const handleDownloadTemplate = async () => {
  if (!order || !order.orderType || !order.id) {
      Swal.fire("Ошибка!", "Не удалось определить данные заказа.", "error");
      return;
  }

  const encodedOrderType = encodeURIComponent(order.orderType); // ✅ Кодируем тип измерения
  const url = `/api/api/templates/download/${encodedOrderType}`;

  try {
      const response = await fetch(url, {
          method: "GET",
          headers: {
              "Authorization": `Bearer ${localStorage.getItem("token")}`
          }
      });

      if (response.status === 404) {
          Swal.fire("Шаблон отсутствует", "Простите, на данный момент шаблон для данного типа измерения отсутствует, обратитесь к руководителю.", "warning");
          return;
      }

      if (!response.ok) {
          throw new Error(`Ошибка сервера: ${await response.text()}`);
      }

      // ✅ Создаём скрытую ссылку для скачивания файла с правильным названием
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = `${order.orderType}, номер заказа ${order.id}.xlsx`; // ✅ Новый формат названия
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      Swal.fire("Успех!", "Шаблон загружен!", "success");

  } catch (error) {
      console.error("❌ Ошибка скачивания шаблона:", error);
      Swal.fire("Ошибка!", "Не удалось скачать шаблон.", "error");
  }
};




if (loading) return <p className="text-center mt-4">Загрузка...</p>;
if (error) return <p className="text-danger text-center mt-4">{error}</p>;
if (!order) return null;

return (
  <div style={{ backgroundColor: "#ffffff", minHeight: "100vh", display: "flex", flexDirection: "column" }}>
    <main style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "15px" }}>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Управление заказом номер: {order.id}</h2>

    {/* Блок "Информация о заказе" (уменьшен и сдвинут влево) */}
<div style={{ 
    width: "35%",  
    maxWidth: "400px",
    padding: "10px", 
    border: "2px solid #ccc", 
    borderRadius: "11px",
    backgroundColor: "#f9f9f9",
    position: "absolute",
    left: "20px",
    top: "120px"
}}>
    <h4>Информация о заказе:</h4>
    
    {/* Полевой техник */}
    <p>
  <strong>Полевой техник:</strong> {order.fieldTechnicianName || "Не назначен"}
</p>


    {/* Офисный техник */}
    <p>
  <strong>Офисный техник:</strong> {order.officeTechnicianName || "Не назначен"}
</p>


    <p><strong>Контактное лицо:</strong> {order.contactPerson || "Нет данных"}</p>
    <p><strong>Заказчик:</strong> {order.customer || "Нет данных"}</p>
    <p><strong>Адрес объекта:</strong> {order.address || "Нет данных"}</p>
    <p><strong>Номер телефона:</strong> {order.phoneNumber || "Нет данных"}</p>
    <p><strong>Email:</strong> {order.email || "Нет данных"}</p>
    <p><strong>Тип измерения:</strong> {order.orderType || "Нет данных"}</p>
</div>



        {/* Проверяем статус заказа */}
        {order.status === "Завершен" && (
          <p style={{ color: "green", fontWeight: "bold", textAlign: "center" }}>
            Заказ завершен! Ожидается утверждение отчета.
          </p>
        )}

        {order.status === "Утвержден" && (
          <p style={{ color: "blue", fontWeight: "bold", textAlign: "center" }}>
            Данный заказ завершен и утвержден руководителем.
          </p>
        )}

{order.status === "Архив" && (
  <p style={{ color: "gray", fontWeight: "bold", textAlign: "center" }}>
    Данный заказ архивирован руководителем.
  </p>
)}


        {/* Кнопки управления (скрываются если заказ Завершен/Утвержден) */}
        {order.status !== "Завершен" && order.status !== "Утвержден" && order.status !== "Архив" && (

          <>
            <button
              className="btn btn-lg"
              style={{ width: "250px", border: "1px solid #90caf9", color: "#000", backgroundColor: "#fff", transition: "0.3s" }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#e3f2fd")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
              onClick={() => navigate(`/office-documents/${order.id}`)}
            >
              Загрузка документов
            </button>

            <button
              className="btn btn-lg"
              style={{ width: "250px", border: "1px solid #90caf9", color: "#000", backgroundColor: "#fff", transition: "0.3s" }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#e3f2fd")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
              onClick={() => navigate(`/upload-results`)}
            >
              Загрузка результатов
            </button>

            {/* 📥 Скачать шаблон протокола */}
            <button
              className="btn btn-lg"
              style={{ width: "250px", border: "1px solid #90caf9", color: "#000", backgroundColor: "#fff", transition: "0.3s" }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#e3f2fd")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
              onClick={handleDownloadTemplate}
            >
              Шаблон протокола
            </button>

            {/* 📤 Загрузить протокол */}
            <label
              className="btn btn-lg"
              style={{
                width: "250px",
                border: "1px solid #90caf9",
                color: "#000",
                backgroundColor: "#fff",
                transition: "0.3s",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#e3f2fd")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
            >
              Загрузить протокол
              <input type="file" accept=".xlsx, .xls" onChange={handleUploadProtocol} style={{ display: "none" }} />
            </label>

            {/* Кнопка "Завершить заказ" (скрывается если заказ Завершен/Утвержден) */}
            <button
              className="btn btn-lg"
              style={{ marginTop: "10px", width: "250px", border: "1px solid #28a745", color: "#000", backgroundColor: "#fff", transition: "0.3s" }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#d4edda")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
              onClick={handleComplete}
            >
              Завершить заказ
            </button>
          </>
        )}
  
        {/* Кнопка "Перейти к отчету" (теперь доступна всегда) */}
            <button
              className="btn btn-lg"
              style={{ marginTop: "20px", width: "250px", border: "1px solid #90caf9", color: "#000", backgroundColor: "#fff", transition: "0.3s" }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#e3f2fd")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
              onClick={() => navigate(`/orders/${order.id}/details`)}
            >
              Перейти к отчету
            </button>

        

        {/* Кнопка "Удалить заказ" (активна всегда) */}
        <button
          className="btn btn-lg"
          style={{ width: "250px", border: "1px solid #d33", color: "#000", backgroundColor: "#fff", transition: "0.3s" }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "#f8d7da")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
        onClick={handleDelete}
      >
        Удалить заказ
      </button>
    </main>
  </div>
);

}

export default OrderManagement;
