import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const showAboutApp = () => {
    MySwal.fire({
        title: 'О AZF-PROCESS',
        html: `
            <p>AZF-PROCESS — это веб-приложение, разработанное специально по заказу Labor OÜ для автоматизации процессов измерений, контроля документации и утверждения отчетов.</p>
            <p>Система обеспечивает полный цикл работы с заказами — от регистрации до завершения и архивирования.</p>
            <p>Основные функции:</p>
            <ul style="text-align: left;">
                <li>✓ Регистрация и управление заказами</li>
                <li>✓ Автоматическое назначение техников в зависимости от типа измерений</li>
                <li>✓ Интеграция с Google Calendar для удобного планирования</li>
                <li>✓ Хранение документации и результатов измерений</li>
                <li>✓ Утверждение отчетов</li>
                <li>✓ Архивирование завершенных заказов</li>
                <li>✓ Гибкое распределение ролей и прав доступа</li>
            </ul>
            <p>Приложение доступно для офисных и полевых техников, а также для администрации, которая осуществляет контроль выполнения заказов.</p>
            <p>Система находится в постоянном развитии. Если у вас возникли вопросы или замечания, пожалуйста, сообщите об этом руководителю.</p>
        `,
        icon: 'info',
        confirmButtonText: 'Закрыть',
        customClass: {
            popup: 'small-modal',
        },
    });
};



function NavigationBar() {
    const [name, setName] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false); // Для управления выпадающим меню

    // Загружаем имя пользователя из localStorage при загрузке страницы
    useEffect(() => {
        const storedName = localStorage.getItem('name');
        const token = localStorage.getItem('token'); // Проверяем, есть ли токен
        setIsAuthenticated(!!token); // Если токен есть - true, если нет - false

        if (storedName) {
            setName(storedName);
        }
    }, []);

    const handleInfoClick = () => {
        MySwal.fire({
            title: 'Информация',
            text: 'При работе в веб-приложении AZF-PROCESS возможны ошибки, так как оно всё ещё находится в разработке. Если вы обнаружите неисправность, пожалуйста, сообщите об этом руководителю. С уважением, Администрация приложения.',
            icon: 'info',
            confirmButtonText: 'Понятно',
            customClass: {
                popup: 'small-modal',
            },
        });
    };

    // Функция выхода из аккаунта
    const handleLogout = () => {
        MySwal.fire({
            title: 'Вы точно хотите выйти?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Да, выйти',
            cancelButtonText: 'Отмена',
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.clear(); // Очищаем localStorage
                window.location.href = '/login'; // Перенаправляем на страницу входа
            }
        });
    };

    // Функция смены пароля
    const handlePasswordChange = async () => {
        const { value: formValues } = await MySwal.fire({
            title: 'Смена пароля',
            html: `
                <input id="current-password" type="password" class="swal2-input" placeholder="Текущий пароль">
                <input id="new-password" type="password" class="swal2-input" placeholder="Новый пароль">
                <input id="confirm-password" type="password" class="swal2-input" placeholder="Повторите новый пароль">
            `,
            focusConfirm: false,
            preConfirm: () => {
                const currentPassword = document.getElementById('current-password').value;
                const newPassword = document.getElementById('new-password').value;
                const confirmPassword = document.getElementById('confirm-password').value;

                if (!currentPassword || !newPassword || !confirmPassword) {
                    MySwal.showValidationMessage('Все поля обязательны для заполнения!');
                    return false;
                }

                if (newPassword !== confirmPassword) {
                    MySwal.showValidationMessage('Пароли не совпадают!');
                    return false;
                }

                return { currentPassword, newPassword };
            }
        });

        if (!formValues) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/change-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    currentPassword: formValues.currentPassword,
                    newPassword: formValues.newPassword
                })
            });

            const data = await response.json();

            if (response.ok) {
                MySwal.fire('Успех!', 'Пароль успешно изменен. Авторизуйтесь заново.', 'success').then(() => {
                    localStorage.clear();
                    window.location.href = '/login';
                });
            } else {
                MySwal.fire('Ошибка!', data.message || 'Не удалось изменить пароль.', 'error');
            }
        } catch (error) {
            console.error('Ошибка при смене пароля:', error);
            MySwal.fire('Ошибка!', 'Произошла ошибка на сервере.', 'error');
        }
    };

    return (
        <>
            {/* Верхняя панель */}
            <nav
                style={{
                    backgroundColor: 'rgb(46, 46, 46)',
                    color: 'white',
                    padding: '10px 20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: 1000,
                }}
            >
<div style={{ display: 'flex', alignItems: 'center' }}>
    {/* Оборачиваем логотип и текст в отдельный span с кликом */}
    <span 
        style={{ 
            display: 'flex', 
            alignItems: 'center', 
            fontSize: '20px', 
            fontWeight: 'bold', 
            cursor: 'pointer' 
        }}
        onClick={() => window.location.href = '/'}
    >
        <img 
            src="/logo.png" 
            alt="AZF Logo" 
            style={{ height: '30px', marginRight: '10px' }} 
        />
        <span>AZF-PROCESS</span>
    </span>

    {/* Версия приложения */}
    <span style={{ fontSize: '12px', marginLeft: '5px', opacity: 0.7 }}>
        (version 1.0 beta)
    </span>

    {/* Вопросительный знак */}
    <span
        style={{
            marginLeft: '8px',
            fontSize: '14px',
            fontWeight: 'bold',
            cursor: 'pointer',
            padding: '3px',
            borderRadius: '50%',
            border: '1px solid white',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '18px',
            height: '18px',
            transition: 'background-color 0.3s',
        }}
        onClick={handleInfoClick}
        onMouseEnter={(e) => (e.target.style.backgroundColor = 'gray')}
        onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')}
    >
        ?
    </span>
        {/* Ссылка "На главную" */}
<span 
    style={{
        marginLeft: '30px',
        fontSize: '14px',
        cursor: 'pointer',
        color: 'rgba(255, 255, 255, 0.7)',
        textDecoration: 'underline',
    }}
    onClick={() => window.location.href = '/'}
    onMouseEnter={(e) => (e.target.style.color = 'white')}
    onMouseLeave={(e) => (e.target.style.color = 'rgba(255, 255, 255, 0.7)')}
>
    На главную
</span>


</div>



                {/* Имя пользователя и кнопки */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {isAuthenticated && name && <span style={{ fontSize: '16px', fontWeight: 'bold' }}> {name}</span>}

                    {isAuthenticated ? (
                        <>
                            {/* Кнопка "Настройки" с выпадающим меню */}
                            <div style={{ position: 'relative' }}>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{ color: 'white', background: 'transparent', border: '1px solid white', padding: '5px 10px', }} 
                                onClick={() => setMenuOpen(!menuOpen)}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = 'rgba(0, 123, 255, 0.3)';
                                    e.target.style.color = 'white'; 
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = 'transparent';
                                    e.target.style.color = 'white'; 
                                }}
                            >
                                ⚙ Настройки
                            </button>

                                {menuOpen && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '100%',
                                            right: 0,
                                            backgroundColor: 'white',
                                            border: '1px solid lightgray',
                                            borderRadius: '5px',
                                            boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
                                            padding: '5px',
                                            zIndex: 1001,
                                        }}
                                    >
                                        <button
                                            className="btn btn-sm"
                                            style={{ width: '100%', textAlign: 'left', border: 'none', padding: '5px' }}
                                            onClick={handlePasswordChange}
                                            onMouseEnter={(e) => (e.target.style.backgroundColor = 'rgba(49, 47, 47, 0.3)')}
                                            onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')}
                                        >
                                            🔑 Смена пароля
                                        </button>
                                    </div>
                                )}
                            </div>

                           <button
                                className="btn btn-outline-danger btn-sm"
                                style={{
                                  background: 'transparent',
                                  border: '1px solid white',
                                  color: 'white',
                                  padding: '5px 10px',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                  transition: 'background-color 0.3s',
                                }}
                                onClick={handleLogout}
                                onMouseEnter={(e) => (e.target.style.backgroundColor = 'rgba(255, 0, 0, 0.3)')}
                                onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')}
                            >
                                🚪 Выход
                            </button>
                        </>
                    ) : (
                        <button className="btn btn-outline-success btn-sm" onClick={() => window.location.href = '/login'}>
                            🔑 Войти
                        </button>
                    )}
                </div>
            </nav>

            

     {/* Нижняя панель */}
<footer
    style={{
        backgroundColor: 'rgb(46, 46, 46)', 
        color: 'white',
        textAlign: 'center',
        padding: '10px 0',
        position: 'fixed',
        bottom: 0,
        width: '100%',
    }}
>
<p style={{ margin: 0 }}>
        © 2025 AZF-PROCESS from 
        <a 
            href="https://azf-labor.ee/ru/" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ color: 'white', textDecoration: 'none', marginLeft: '5px' }}
        >
            azf-labor
        </a>
    </p>

    {/* Ссылка "О AZF-PROCESS" в левом нижнем углу */}
    <p 
        style={{ 
            fontSize: "12px", 
            cursor: "pointer", 
            color: "rgba(255, 255, 255, 0.5)", // Цвет как у "developed by Oleksandr Taradin"
            position: "absolute", 
            left: "20px", // Отступ слева
            bottom: "5px", // Корректируем по вертикали
            textDecoration: "none" // Без подчеркивания
        }} 
        onClick={showAboutApp}
        onMouseEnter={(e) => (e.target.style.color = "white")} 
        onMouseLeave={(e) => (e.target.style.color = "rgba(255, 255, 255, 0.5)")}
    >
        О AZF-PROCESS
    </p>

    <p style={{ margin: 0, fontSize: '12px', color: 'rgba(255, 255, 255, 0.77)' }}>
        developed by Oleksandr Taradin
    </p>
</footer>
    </>
  );
}

export default NavigationBar;

