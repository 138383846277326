import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

function TemplateManagement() {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true); 

  // ✅ Загрузка списка шаблонов
  const fetchTemplates = async () => {
    try {
        console.log("🔄 Загружаем шаблоны...");

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/templates`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
                "Cache-Control": "no-cache",
                "Pragma": "no-cache",
            },
        });

        console.log("📌 [DEBUG] Статус ответа:", response.status);

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Ошибка загрузки шаблонов: ${errorText}`);
        }

        const data = await response.json();
        console.log("✅ [DEBUG] Полученные шаблоны:", data);

        // **Фиксируем проблему:** Проверяем, есть ли `orderType`
        data.forEach(template => {
            if (!template.orderType) {
                console.warn(`⚠️ У шаблона "${template.name}" отсутствует orderType!`);
            }
        });

        setTemplates(data);
    } catch (error) {
        console.error("❌ Ошибка загрузки шаблонов:", error);
        Swal.fire("Ошибка!", "Не удалось загрузить список шаблонов.", "error");
    } finally {
        setLoading(false);
    }
};

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleUpload = async () => {
    const { value: orderType } = await Swal.fire({
      title: "Выберите тип измерения",
      input: "select",
      inputOptions: {
        "Вентиляция": "Вентиляция",
        "Шум от техносистем": "Шум от техносистем",
        "Опрессовка вентиляционных систем": "Опрессовка вентиляционных систем",
        "Освещенность": "Освещенность",
        "Замеры уровня звукоизоляции": "Замеры уровня звукоизоляции",
        "Термосъёмка": "Термосъёмка",
        "Вибрация": "Вибрация",
        "Микроклимат": "Микроклимат"
      },
      showCancelButton: true,
      confirmButtonText: "Далее",
      cancelButtonText: "Отмена"
    });
  
    if (!orderType) return; // Если пользователь отменил, выходим
  
    const { value: file } = await Swal.fire({
      title: "Загрузите шаблон",
      input: "file",
      inputAttributes: { accept: ".xlsx" },
      showCancelButton: true,
      confirmButtonText: "Загрузить",
      cancelButtonText: "Отмена",
    });
  
    if (!file) return;
  
    const formData = new FormData();
    formData.append("template", file);
    formData.append("orderType", orderType);  // ✅ Теперь передаём `orderType`
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/templates/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,  // ✅ Добавляем токен
        },
        body: formData, // ✅ Передаём FormData без `Content-Type`, браузер установит его сам
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Ошибка загрузки файла: ${errorText}`);
      }
  
      Swal.fire("Успех!", "Шаблон успешно загружен!", "success");
      fetchTemplates(); // ✅ Обновляем список шаблонов
    } catch (error) {
      console.error("❌ Ошибка загрузки шаблона:", error);
      Swal.fire("Ошибка!", "Не удалось загрузить шаблон.", "error");
    }
  };
  
  // ✅ Функция обновления шаблона
  const handleUpdate = async (orderType) => {
    const { value: file } = await Swal.fire({
      title: `Обновить шаблон для ${orderType}`,
      input: "file",
      inputAttributes: { accept: ".xlsx" },
      showCancelButton: true,
      confirmButtonText: "Обновить",
      cancelButtonText: "Отмена",
    });

    if (!file) return;

    const formData = new FormData();
    formData.append("template", file);
    formData.append("orderType", orderType);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/templates/upload`, {
        method: "POST",
        headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` },
        body: formData,
      });

      if (!response.ok) throw new Error(`Ошибка обновления файла: ${await response.text()}`);

      Swal.fire("Успех!", "Шаблон успешно обновлен!", "success");
      fetchTemplates();
    } catch (error) {
      console.error("❌ Ошибка обновления шаблона:", error);
      Swal.fire("Ошибка!", "Не удалось обновить шаблон.", "error");
    }
  };

  // ✅ Функция удаления шаблона
  const handleDelete = async (orderType) => {
    const result = await Swal.fire({
      title: `Удалить шаблон для ${orderType}?`,
      text: "Это повлечет за собой невозможность скачивания шаблона техниками.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Удалить",
      cancelButtonText: "Отмена",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(`/api/api/templates/${encodeURIComponent(orderType)}`, {
          method: "DELETE",
          headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` },
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Ошибка удаления файла: ${errorText}`);
        }

        Swal.fire("Удалено!", "Шаблон успешно удален.", "success");
        fetchTemplates(); // ✅ Обновляем список шаблонов после удаления
      } catch (error) {
        console.error("❌ Ошибка удаления шаблона:", error);
        Swal.fire("Ошибка!", "Не удалось удалить шаблон.", "error");
      }
    }
};

  if (loading) return <p className="text-center">Загрузка шаблонов...</p>;

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Редактирование шаблонов</h2>

      <button className="btn btn-outline-primary mb-3" onClick={handleUpload}>
        ➕ Добавить шаблон
      </button>

      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="table-light">
            <tr>
              <th>Тип измерения</th>
              <th>Название протокола</th>
              <th>Дата обновления</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            {templates.map((template, index) => (
              <tr key={index}>
                <td>{template.orderType || "❌ Ошибка"}</td>
                <td>{template.name}</td>  
                <td>{template.updatedAt ? new Date(template.updatedAt).toLocaleDateString() : "Не обновлялся"}</td>
                <td>
                  <button className="btn btn-sm btn-outline-warning" onClick={() => handleUpdate(template.orderType)}>
                    🔄 Обновить
                  </button>
                  <button className="btn btn-sm btn-outline-danger ms-2" onClick={() => handleDelete(template.orderType)}>
                    ❌ Удалить
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TemplateManagement;
