import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!name || !email || !password) {
      Swal.fire({
        icon: 'error',
        title: 'Ошибка!',
        text: 'Все поля обязательны для заполнения!',
      });
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL || "/api";
const token = localStorage.getItem("token") || "";

const response = await fetch(`${apiUrl}/register`, {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`
  },
  body: JSON.stringify({ name, email, password })
});

      

      const data = await response.json();

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Успех!',
          text: 'Регистрация прошла успешно!',
          showConfirmButton: false,
          timer: 2000,
        });

        setTimeout(() => navigate('/login'), 2000); // Перенаправляем на страницу логина
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка!',
          text: data.error || 'Не удалось зарегистрироваться. Попробуйте снова.',
        });
      }
    } catch (error) {
      console.error('Ошибка при регистрации:', error);
      Swal.fire({
        icon: 'error',
        title: 'Ошибка!',
        text: 'Произошла ошибка. Попробуйте снова.',
      });
    }
  };

  return (
    <div className="container mt-5" style={{ maxWidth: '400px' }}>
      <h2 className="text-center mb-4" style={{ color: '#555' }}>Регистрация</h2>
      <div className="card shadow">
        <div className="card-body">
          <form onSubmit={handleRegister}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Имя</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Введите ваше имя"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Электронная почта</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Введите вашу почту"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Пароль</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Введите ваш пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="btn w-100"
              style={{
                border: '1px solid #007bff',
                backgroundColor: 'transparent',
                color: '#007bff',
                transition: 'all 0.3s',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#007bff';
                e.target.style.color = '#fff';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'transparent';
                e.target.style.color = '#007bff';
              }}
            >
              Зарегистрироваться
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Register;
