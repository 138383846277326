import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from './Register';
import Login from './Login';
import PrepareOrder from './PrepareOrder';
import UploadResults from './UploadResults';
import Orders from './Orders';
import OfficeDocumentation from './OfficeDocumentation';
import OrderDetails from './OrderDetails';
import ArchivedOrders from './ArchivedOrders';
import CreateOrder from './CreateOrder';
import MainPage from './MainPage';
import NavigationBar from './components/NavigationBar';
import UnderConstruction from "./UnderConstruction";
import OrderManagement from "./OrderManagement";
import TemplateManagement from "./TemplateManagement";





// Основной компонент приложения
function App() {
  return (
    <Router>
      <NavigationBar /> {/* ✅ Добавляем навигационный бар на все страницы */}
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/prepare-order" element={<PrepareOrder />} /> 
        <Route path="/schedule-technician" element={<UnderConstruction />} />
        <Route path="/upload-results" element={<UploadResults />} />
        <Route path="/view-results" element={<UnderConstruction />} />
        <Route path="/create-order" element={<CreateOrder />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/office-documents/:orderId" element={<OfficeDocumentation />} />
        <Route path="/orders/:id/details" element={<OrderDetails />} />
        <Route path="/archived-orders" element={<ArchivedOrders />} />
        <Route path="/order-management/:orderId" element={<OrderManagement />} />
        <Route path="/template-management" element={<TemplateManagement />} />



      </Routes>
    </Router>
  );
}

export default App;
