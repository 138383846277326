import React from "react";

function UnderConstruction() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <h2 style={{ color: "#6c757d" }}>ДАННАЯ ФУНКЦИЯ НАХОДИТСЯ В РАЗРАБОТКЕ</h2>
    </div>
  );
}

export default UnderConstruction;
