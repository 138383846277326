import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function OfficeDocumentation() {
    const { orderId } = useParams();
    const [documents, setDocuments] = useState([]);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // Функция для загрузки документов
    const fetchDocuments = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetch(`/api/documents/${orderId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            if (!response.ok) {
                throw new Error('Не удалось загрузить документы');
            }
            const data = await response.json();
            setDocuments(data);
        } catch (err) {
            setError(err.message);
            console.error('Ошибка при загрузке документов:', err);
        } finally {
            setLoading(false);
        }
    }, [orderId]);

    useEffect(() => {
        fetchDocuments();
    }, [fetchDocuments]);



    //Удалене загруженного документа

    const handleDelete = async (documentId) => {
        const result = await MySwal.fire({
            title: "Вы уверены?",
            text: "Этот документ будет удалён без возможности восстановления!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Удалить",
            cancelButtonText: "Отмена",
        });
    
        if (result.isConfirmed) {
            try {
                const response = await fetch(`/api/documents/${documentId}`, {
                    method: "DELETE",
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                });
    
                if (!response.ok) {
                    throw new Error("Ошибка при удалении документа");
                }
    
                MySwal.fire("Удалено!", "Документ успешно удалён.", "success");
    
                // Обновляем список документов
                fetchDocuments();
            } catch (err) {
                MySwal.fire("Ошибка!", "Не удалось удалить документ.", "error");
                console.error("Ошибка при удалении документа:", err);
            }
        }
    };
    


    // Функция загрузки нового документа
    const handleUpload = async (e) => {
        e.preventDefault();
        if (!file) {
            MySwal.fire({
                icon: 'warning',
                title: 'Ошибка!',
                text: 'Выберите файл для загрузки!',
            });
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('order_id', orderId);

        try {
            Swal.fire({
                title: 'Загрузка...',
                text: 'Пожалуйста, подождите',
                allowOutsideClick: false,
                didOpen: () => Swal.showLoading(),
            });

            const response = await fetch(`/api/upload-documents?order_id=${orderId}`, {
                method: 'POST',
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Ошибка при загрузке документа');
            }

            Swal.fire({
                icon: 'success',
                title: 'Успех!',
                text: 'Документ успешно загружен!',
            });

            setFile(null);
            fetchDocuments();
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Ошибка!',
                text: 'Ошибка при загрузке документа',
            });
            console.error('Ошибка при загрузке документа:', err);
        }
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Документы для заказа #{orderId}</h2>

            {error && <p className="text-danger text-center">Ошибка: {error}</p>}

            <div className="card mx-auto" style={{ maxWidth: '800px' }}>
                <div className="card-body">
                    <h5 className="card-title text-center">Загруженные документы</h5>

                    {loading ? (
                        <p className="text-center">Загрузка...</p>
                    ) : documents.length > 0 ? (
                        <div className="table-responsive">
                            <table className="table table-bordered mt-3">
                                <thead className="table-light">
                                    <tr>
                                        <th>Название</th>
                                        <th>Дата загрузки</th>
                                        <th>Действие</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documents.map((doc) => (
                                        <tr key={doc.id}>
                                            <td>{doc.original_name}</td>
                                            <td>{new Date(doc.upload_date).toLocaleDateString()}</td>
                                            <td>
                                        <button
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={() => handleDelete(doc.id)}
                                        >
                                            Удалить
                                        </button>
                                    </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p className="text-center text-muted">Документы отсутствуют</p>
                    )}
                </div>
            </div>

            <div className="card mx-auto mt-4" style={{ maxWidth: '800px' }}>
                <div className="card-body">
                    <h5 className="card-title text-center">Загрузить новый документ</h5>
                    <form onSubmit={handleUpload}>
                        <div className="mb-3">
                            <input
                                type="file"
                                className="form-control"
                                onChange={(e) => setFile(e.target.files[0])}
                            />
                        </div>
                        <button type="submit" className="btn btn-primary w-100">Загрузить</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default OfficeDocumentation;
